define("discourse/plugins/chat/discourse/initializers/chat-sidebar", ["exports", "@glimmer/tracking", "@ember/service", "@ember/string", "@ember/template", "discourse/helpers/decorate-username-selector", "discourse/lib/plugin-api", "discourse/lib/text", "discourse/lib/utilities", "discourse-common/lib/avatar-utils", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/modal/new-message", "discourse/plugins/chat/discourse/lib/init-sidebar-state"], function (_exports, _tracking, _service, _string, _template, _decorateUsernameSelector, _pluginApi, _text, _utilities, _avatarUtils, _getUrl, _decorators, _discourseI18n, _newMessage, _initSidebarState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = _exports.default = {
    name: "chat-sidebar",
    initialize(container) {
      this.chatService = container.lookup("service:chat");
      if (!this.chatService.userCanChat) {
        return;
      }
      this.siteSettings = container.lookup("service:site-settings");
      (0, _pluginApi.withPluginApi)("1.8.0", api => {
        api.addSidebarPanel(BaseCustomSidebarPanel => class ChatSidebarPanel extends BaseCustomSidebarPanel {
          constructor() {
            super(...arguments);
            _defineProperty(this, "key", _initSidebarState.CHAT_PANEL);
            _defineProperty(this, "switchButtonLabel", _discourseI18n.default.t("sidebar.panels.chat.label"));
            _defineProperty(this, "switchButtonIcon", "d-chat");
            _defineProperty(this, "switchButtonDefaultUrl", (0, _getUrl.default)("/chat"));
          }
        });
        (0, _initSidebarState.initSidebarState)(api, api.getCurrentUser());
      });
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        if (this.siteSettings.enable_public_channels) {
          api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
            var _class2, _descriptor, _descriptor2;
            const SidebarChatChannelsSectionLink = class extends BaseCustomSidebarSectionLink {
              constructor(_ref) {
                let {
                  channel,
                  chatService
                } = _ref;
                super(...arguments);
                this.channel = channel;
                this.chatService = chatService;
              }
              get name() {
                return (0, _string.dasherize)(this.channel.slugifiedTitle);
              }
              get classNames() {
                const classes = [];
                if (this.channel.currentUserMembership.muted) {
                  classes.push("sidebar-section-link--muted");
                }
                if (this.channel.id === this.chatService.activeChannel?.id) {
                  classes.push("sidebar-section-link--active");
                }
                classes.push(`channel-${this.channel.id}`);
                return classes.join(" ");
              }
              get route() {
                return "chat.channel";
              }
              get models() {
                return this.channel.routeModels;
              }
              get text() {
                return (0, _template.htmlSafe)((0, _text.emojiUnescape)(this.channel.escapedTitle));
              }
              get prefixType() {
                return "icon";
              }
              get prefixValue() {
                return "d-chat";
              }
              get prefixColor() {
                return this.channel.chatable.color;
              }
              get title() {
                return this.channel.escapedDescription ? (0, _template.htmlSafe)(this.channel.escapedDescription) : `${this.channel.escapedTitle} ${_discourseI18n.default.t("chat.title")}`;
              }
              get prefixBadge() {
                return this.channel.chatable.read_restricted ? "lock" : "";
              }
              get suffixType() {
                return "icon";
              }
              get suffixValue() {
                return this.channel.tracking.unreadCount > 0 ||
                // We want to do this so we don't show a blue dot if the user is inside
                // the channel and a new unread thread comes in.
                this.chatService.activeChannel?.id !== this.channel.id && this.channel.unreadThreadsCountSinceLastViewed > 0 ? "circle" : "";
              }
              get suffixCSSClass() {
                return this.channel.tracking.mentionCount > 0 ? "urgent" : "unread";
              }
            };
            const SidebarChatChannelsSection = (_class2 = class _class2 extends BaseCustomSidebarSection {
              constructor() {
                super(...arguments);
                _initializerDefineProperty(this, "currentUser", _descriptor, this);
                _initializerDefineProperty(this, "currentUserCanJoinPublicChannels", _descriptor2, this);
                if (container.isDestroyed) {
                  return;
                }
                this.chatService = container.lookup("service:chat");
                this.chatChannelsManager = container.lookup("service:chat-channels-manager");
                this.router = container.lookup("service:router");
              }
              get sectionLinks() {
                return this.chatChannelsManager.publicMessageChannels.map(channel => new SidebarChatChannelsSectionLink({
                  channel,
                  chatService: this.chatService
                }));
              }
              get name() {
                return "chat-channels";
              }
              get title() {
                return _discourseI18n.default.t("chat.chat_channels");
              }
              get text() {
                return _discourseI18n.default.t("chat.chat_channels");
              }
              get actions() {
                return [{
                  id: "browseChannels",
                  title: _discourseI18n.default.t("chat.channels_list_popup.browse"),
                  action: () => this.router.transitionTo("chat.browse.open")
                }];
              }
              get actionsIcon() {
                return "pencil-alt";
              }
              get links() {
                return this.sectionLinks;
              }
              get displaySection() {
                return this.sectionLinks.length > 0 || this.currentUserCanJoinPublicChannels;
              }
            }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.inject], {
              configurable: true,
              enumerable: true,
              writable: true,
              initializer: null
            }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "currentUserCanJoinPublicChannels", [_tracking.tracked], {
              configurable: true,
              enumerable: true,
              writable: true,
              initializer: function () {
                return this.currentUser && (this.currentUser.staff || this.currentUser.has_joinable_public_channels);
              }
            })), _class2);
            return SidebarChatChannelsSection;
          }, _initSidebarState.CHAT_PANEL);
        }
        api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
          var _class4, _class6, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
          const SidebarChatDirectMessagesSectionLink = (_class4 = class _class4 extends BaseCustomSidebarSectionLink {
            constructor(_ref2) {
              let {
                channel,
                chatService,
                currentUser
              } = _ref2;
              super(...arguments);
              _defineProperty(this, "route", "chat.channel");
              _defineProperty(this, "suffixType", "icon");
              _defineProperty(this, "suffixCSSClass", "urgent");
              _defineProperty(this, "hoverType", "icon");
              _defineProperty(this, "hoverValue", "times");
              _defineProperty(this, "hoverTitle", _discourseI18n.default.t("chat.direct_messages.close"));
              this.channel = channel;
              this.chatService = chatService;
              this.currentUser = currentUser;
              if (this.oneOnOneMessage) {
                const user = this.channel.chatable.users[0];
                if (user.username !== _discourseI18n.default.t("chat.deleted_chat_username")) {
                  user.trackStatus();
                }
              }
            }
            willDestroy() {
              if (this.oneOnOneMessage) {
                this.channel.chatable.users[0].stopTrackingStatus();
              }
            }
            get oneOnOneMessage() {
              return this.channel.chatable.users.length === 1;
            }
            get contentComponentArgs() {
              return this.channel.chatable.users[0].get("status");
            }
            get contentComponent() {
              if (this.oneOnOneMessage) {
                return "user-status-message";
              }
            }
            get name() {
              return this.channel.slugifiedTitle;
            }
            get classNames() {
              const classes = [];
              if (this.channel.currentUserMembership.muted) {
                classes.push("sidebar-section-link--muted");
              }
              if (this.channel.id === this.chatService.activeChannel?.id) {
                classes.push("sidebar-section-link--active");
              }
              classes.push(`channel-${this.channel.id}`);
              return classes.join(" ");
            }
            get models() {
              return this.channel.routeModels;
            }
            get title() {
              return _discourseI18n.default.t("chat.placeholder_channel", {
                channelName: this.channel.escapedTitle
              });
            }
            get text() {
              if (this.channel.chatable.group) {
                return this.channel.title;
              } else {
                const username = this.channel.escapedTitle.replaceAll("@", "");
                return (0, _template.htmlSafe)(`${(0, _utilities.escapeExpression)(username)}${(0, _decorateUsernameSelector.decorateUsername)((0, _utilities.escapeExpression)(username))}`);
              }
            }
            get prefixType() {
              if (this.channel.chatable.group) {
                return "text";
              } else {
                return "image";
              }
            }
            get prefixValue() {
              if (this.channel.chatable.group) {
                return this.channel.membershipsCount;
              } else {
                return (0, _avatarUtils.avatarUrl)(this.channel.chatable.users[0].avatar_template, "tiny");
              }
            }
            get prefixCSSClass() {
              const activeUsers = this.chatService.presenceChannel.users;
              const user = this.channel.chatable.users[0];
              if (!!activeUsers?.findBy("id", user?.id) || !!activeUsers?.findBy("username", user?.username)) {
                return "active";
              }
              return "";
            }
            get suffixValue() {
              return this.channel.tracking.unreadCount > 0 ? "circle" : "";
            }
            get hoverAction() {
              return event => {
                event.stopPropagation();
                event.preventDefault();
                this.chatService.unfollowChannel(this.channel);
              };
            }
          }, (_applyDecoratedDescriptor(_class4.prototype, "willDestroy", [_decorators.bind], Object.getOwnPropertyDescriptor(_class4.prototype, "willDestroy"), _class4.prototype)), _class4);
          const SidebarChatDirectMessagesSection = (_class6 = class _class6 extends BaseCustomSidebarSection {
            constructor() {
              super(...arguments);
              _initializerDefineProperty(this, "site", _descriptor3, this);
              _initializerDefineProperty(this, "modal", _descriptor4, this);
              _initializerDefineProperty(this, "router", _descriptor5, this);
              _initializerDefineProperty(this, "currentUser", _descriptor6, this);
              _initializerDefineProperty(this, "userCanDirectMessage", _descriptor7, this);
              if (container.isDestroyed) {
                return;
              }
              this.chatService = container.lookup("service:chat");
              this.chatChannelsManager = container.lookup("service:chat-channels-manager");
            }
            get sectionLinks() {
              return this.chatChannelsManager.truncatedDirectMessageChannels.map(channel => new SidebarChatDirectMessagesSectionLink({
                channel,
                chatService: this.chatService,
                currentUser: this.currentUser
              }));
            }
            get name() {
              return "chat-dms";
            }
            get title() {
              return _discourseI18n.default.t("chat.direct_messages.title");
            }
            get text() {
              return _discourseI18n.default.t("chat.direct_messages.title");
            }
            get actions() {
              if (!this.userCanDirectMessage) {
                return [];
              }
              return [{
                id: "startDm",
                title: _discourseI18n.default.t("chat.direct_messages.new"),
                action: () => {
                  this.modal.show(_newMessage.default);
                }
              }];
            }
            get actionsIcon() {
              return "plus";
            }
            get links() {
              return this.sectionLinks;
            }
            get displaySection() {
              return this.sectionLinks.length > 0 || this.userCanDirectMessage;
            }
          }, (_descriptor3 = _applyDecoratedDescriptor(_class6.prototype, "site", [_service.inject], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          }), _descriptor4 = _applyDecoratedDescriptor(_class6.prototype, "modal", [_service.inject], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          }), _descriptor5 = _applyDecoratedDescriptor(_class6.prototype, "router", [_service.inject], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          }), _descriptor6 = _applyDecoratedDescriptor(_class6.prototype, "currentUser", [_service.inject], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          }), _descriptor7 = _applyDecoratedDescriptor(_class6.prototype, "userCanDirectMessage", [_tracking.tracked], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: function () {
              return this.chatService.userCanDirectMessage;
            }
          })), _class6);
          return SidebarChatDirectMessagesSection;
        }, "chat");
      });
    }
  };
});